import store from "../store";
import {HTTP,HTTPFILE} from "./axiosHelper.js";
import router from "../router";

export default function setup() {
// Deal with all responses containing a 401 error to try to refresh the access token if possible
    HTTP.interceptors.response.use(
        // No special handling of responses needed. We return it as it comes in.
        response => {
            return response;
        },
        // This object is not null if an error occured
        error => {
            // Check if it is a 401 Unauthorized error
            if (error.response.status === 401 && error.response.data.status !== false) {
                console.log("401!!!: ",error.response);
                // Try to refresh the access token
                //console.log("store.state.status.refreshCount: ",store.state.status.refreshCount);
                if(store.state.status.refreshCount <= 10){    
                    return refreshAccessToken()
                    .then(result => {
                        // Was refreshing the access token successfull?
                        if (result === true) {
                            //SoNo extract content-type from header and reset it (if i just take the error from the error the invalid token is also re sent endless loop)
                            var contentType = error.config.headers['Content-Type']
                            if(contentType){
                                var headers = {'Content-Type':contentType}
                            }

                            //check if login failed
                            if(error.config.data !== "login failed" && error.config.status !== false){
                                // Repeat the request
                                return HTTP({
                                    method: error.config.method,
                                    url: error.config.url,
                                    data: error.config.data,
                                    headers: headers
                                });
                            }
                        } else {
                            // If the access token could not be refreshed we reject the promise and the code responsible for the request has to handle it.
                            return Promise.reject(Error("Invalid Login Credentials."));
                        }
                    })
                    .catch(error => {
                        Promise.reject(error);
                    });
                }else{
                    store.commit('logout',false);
                    router.push('/login');
                    return Promise.reject(error,"refresh Token failed after 10 tries!");
                }
            }
            // No special treatement of any other error
            return Promise.reject(error);
        }
    );
    HTTPFILE.interceptors.response.use(
        // No special handling of responses needed. We return it as it comes in.
        response => {
            return response;
        },
        // This object is not null if an error occured
        error => {
            if (typeof error.response === 'undefined') {
                return Promise.reject(Error("A network error occurred."))
            }
            // Check if it is a 401 Unauthorized error
            if (error.response.status === 401 && error.response.data.status !== false) {
                console.log("401!!!: ",error.response);
                // Try to refresh the access token
                //console.log("store.state.status.refreshCount: ",store.state.status.refreshCount);
                if(store.state.status.refreshCount <= 10){    
                    return refreshAccessToken()
                    .then(result => {
                        // Was refreshing the access token successfull?
                        if (result === true) {
                            //SoNo extract content-type from header and reset it (if i just take the error from the error the invalid token is also re sent endless loop)
                            var contentType = error.config.headers['Content-Type']
                            if(contentType){
                                var headers = {'Content-Type':contentType}
                            }

                            //check if login failed
                            if(error.config.data !== "login failed" && error.config.status !== false){
                                // Repeat the request
                                return HTTPFILE({
                                    method: error.config.method,
                                    url: error.config.url,
                                    data: error.config.data,
                                    headers: headers
                                });
                            }
                        } else {
                            // If the access token could not be refreshed we reject the promise and the code responsible for the request has to handle it.
                            return Promise.reject(error("Invalid Login Credentials."));
                        }
                    })
                    .catch(error => {
                        Promise.reject(error);
                    });
                }else{
                    store.commit('logout',false);
                    router.push('/login');
                    return Promise.reject(error,"refresh Token failed after 10 tries!");
                }
            }
            // No special treatement of any other error
            return Promise.reject(error);
        }
    );
}
function refreshAccessToken(){
    //SoNo, return promise whith result true or false depending on refresh success (maybe a better way to use promise)
    return store.dispatch('refreshToken').then(() => {
        return true;
    }).catch(() => {
        return false;
    });
}
