import Vue from "vue";
import VueI18n from 'vue-i18n';
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import $http from "./helpers/axiosHelper.js"

import AppHeader from "@/components/Header.vue";
import AppFooter from "@/components/Footer.vue";
import BigNavIcon from "@/components/BigNavIcon.vue";
import Hint from "@/components/Hint.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import RouterButton from "@/components/FormFields/RouterButton.vue";
import Button from "@/components/FormFields/Button.vue";
import ConfirmPopup from '@/components/Popups/ConfirmPopup.vue';

// importing the helper
import axiosInterceptors from '@/helpers/axiosInterceptors.js'

// and running it somewhere here
axiosInterceptors()

Vue.use(VueI18n);

import { languages } from './locales/index.js'
import { defaultLocale } from './locales/index.js'
const messages = Object.assign(languages)

Vue.config.productionTip = false;

Vue.prototype.$http = $http;

Vue.component('appheader', AppHeader);
Vue.component('appfooter', AppFooter);
Vue.component('bignavicon', BigNavIcon);
Vue.component('hint', Hint);
Vue.component('formWrap', FormWrapper);
Vue.component('routerButton',RouterButton);
Vue.component('formButton',Button);
Vue.component('confirmpopup',ConfirmPopup);

const i18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'de',
    messages
})

new Vue({
  router,
  store,
  render: h => h(App),
  i18n
}).$mount("#app");

export {i18n};