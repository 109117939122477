<template>
  <div id="app">
    <confirmpopup :active='updateExists && noUnsentDataExists' :msg='$t("messages.newUpdate")' :confirmed='update'></confirmpopup>
    <burgerbutton v-if="isLoggedIn"></burgerbutton>
    <selectlocale />
    <!--<burgerbutton v-if="this.$store.getters.isLoggedIn"></burgerbutton>-->
    <transition name="animate-slide">
        <burgermenu v-if='isNavOpen'></burgermenu>
    </transition>
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
import BurgerButton from "@/components/BurgerButton.vue";
import BurgerMenu from "@/components/BurgerMenu.vue";
import SelectLocale from './components/SelectLocale.vue'
import update from './mixins/update'
export default {
  data(){
      return{
          confirmActive: true,
          version: process.env.VUE_APP_VERSION,
      }
  },
  components:{
    'burgerbutton':BurgerButton,
    'burgermenu':BurgerMenu,
    'selectlocale':SelectLocale
  },
  computed: {
    isNavOpen() {
      return this.$store.state.isNavOpen;
    },
    isLoggedIn(){
      return this.$store.state.isLoggedIn;
    },
    noUnsentDataExists(){
      return this.$store.getters.noUnsentDataExists;
    },
    updateReady(){
      return this.updateExists;
    },
  },
  mixins: [update],
  methods:{
    decline:function(){
      this.confirmActive = false; 
    },
    update(){
      //call refreshapp from mixin
      //console.log("UPDATE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      this.$store.dispatch('logout');
      this.refreshApp();
      this.$store.dispatch('setStatusMsg',this.$t("messages.updateSuccess1")+this.version+this.$t("messages.updateSuccess2"));
    }
  },
}

</script>

<style lang="scss">
@import "./assets/scss/styles.scss";

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body{
  margin:0;
}
#app {
  display: flex;
  flex-direction: column;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height:100vh;
}

#nav {
  padding: 30px;
  margin-top:30px;
  a {
    font-weight: bold;
  }
}

.animate-slide-enter{
  margin-left:-100vw;
}
.animate-slide-enter-active{
  transition: margin-left .5s;
}
.animate-slide-enter-to{
  margin-left:0vw;
}
.animate-slide-leave-active{
  margin-left:-100vw;
  transition: margin-left .5s;
}
</style>
