import {HTTP, HTTPFILE} from '../helpers/axiosHelper.js';
import Vue from 'vue'

const loadFromCache = (state, cached) => {
    console.log("loadFromCache");
    if (cached) {

      //filter out key wich schould not be cached (for example status)
      var getCachedOrInitial = function(key,state,cache){
        if(key == 'status' || key == 'keepData'|| key == 'initialized'){
            if(typeof state[key] !== 'object'){
                return state[key];
            }
            return Object.assign({},state[key]);
        }else{
            if(typeof cache[key] !== 'object'){
                if(cache[key] !== 'undefined'){
                    //console.log("cache["+key+"]: ", "value: ",cache[key]);
                    //console.log("state["+key+"]: ", "value: ",state[key]);
                    /*if(key == 'local' && cache[key].curCheck.checkListId < 0){
                        
                    }*/
                    return cache[key];
                }
                else{
                    return state[key];
                }
            }
            
            //console.log("cache["+key+"]: ", "value: ",cache[key]);
            //console.log("state["+key+"]: ", "value: ",state[key]);
            //var stringifiedObject = JSON.stringify(cache[key]);
            //return JSON.parse(stringifiedObject);
            return Object.assign({},state[key],cache[key]);
        }
      }

      if(cached.isLoggedIn == true){
        Object.keys(cached).forEach((key) => {
            state[key] = getCachedOrInitial(key,state, cached);
            
        });
        //console.log("loadFromCache isLoggedIn:" ,state.isLoggedIn)
      }
    }
    state.initialized = true;
    //state.isLoggedIn = true;
};
const toggleNav = (state) => {
  state.isNavOpen = !state.isNavOpen
};
const setCurCheckStartTime = (state) =>{
  if(!state.local.curCheck.startTime){
    state.local.curCheck.startTime = new Date();
  }
};
const setTruck = (state, id) => {
    state.local.curCheck.chosenTruck = id;
    state.local.curCheck.checklistStatus[state.local.curCheck.lkwDataId].fieldStatus = 'partial'
    
    //set all the form ids for the chosen truck
    var vehicleForms = state.vehiclesAPI.vehicles[id].forms;
    var checkList= vehicleForms.filter(obj => {
        return obj.form_type === 'checklist';
    })
    //temporarily store base ids in the store -> later replace them with the formId
    //TODO: to allow multiple lists from one type iterate arrays not use [0]
        console.log("checklist: ",checkList);
        checkList.forEach( (list, key) => {
            state.local.curCheck.checkListId[key].baseId = list.form_base_id
        });
    //}

    var defectsList = vehicleForms.filter(obj => {
        return obj.form_type === 'faultlist';
    })
    if(defectsList[0] !== undefined){
        state.local.curCheck.defectsId.baseId = defectsList[0].form_base_id;
    }

    var endList = vehicleForms.filter(obj => {
        return obj.form_type === 'endcontroll';
    })
    if(endList[0] !== undefined){
        state.local.curCheck.endId.baseId = endList[0].form_base_id;
    }

    var todoList = vehicleForms.filter(obj => {
        return obj.form_type === 'todolist';
    })
    if(todoList[0] !== undefined){
        state.local.curCheck.toDoId.baseId = todoList[0].form_base_id;
    }

    var lkwForm = vehicleForms.filter(obj => {
        return obj.form_type === 'lkwform';
    })
    if(lkwForm && lkwForm.length > 0){
        if(state.local.curCheck.lkwFormId !== undefined){
            state.local.curCheck.lkwFormId.baseId = lkwForm[0].form_base_id;
        }
    }
};
const setTrailer = (state, id) => {
  state.local.curCheck.chosenTrailer = id;
  state.local.curCheck.checklistStatus[state.local.curCheck.lkwDataId].fieldStatus = 'ok'
};
const setType = (state, id) => {
  state.local.curCheck.vehicleType = id;
};
/*setLkwDataStatus(state,status){
  state.local.curCheck.checklistStatus[state.local.curCheck.chosenTruck].fieldStatus = status;
},*/
const setTimerId = (state, id) => {
  state.local.nachtragTimer.timerId = id;
};
const setFormStatusFields = (state,payload) => {
  state.local.curCheck.checklistStatus[payload.formId].fieldStatus = payload.value;
  //state.local.curCheck.checklistStatus[payload.formId].sendStatus = 'open';
};
const setFormStatusSend = (state,payload) => {
    console.log("payload.formId: ",payload.formId)
  state.local.curCheck.checklistStatus[payload.formId].sendStatus = payload.value;
};
const trackTime = (state, payload) => {
  state.local.nachtragTimer.currentlyTracked = payload.type;
  let now = Date.now();
  console.log("now:",now);
  if(state.local.nachtragTimer.trackedTimesEntries.length-1 >= 0){
    //only set endTime when it was null before, so Values are not overwritten
    if(state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length-1].endTime == null){
      //console.log("state.local.nachtragTimer.trackedTimesEntries:",state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length-1].endTime);
      state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length-1].endTime = now;
      //console.log("state.local.nachtragTimer.trackedTimesEntries:",state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length-1].endTime);
    }
    if(state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length-1].geoEnd === undefined){
        //console.log("state.local.nachtragTimer.trackedTimesEntries:",state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length-1].endTime);
        state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length-1].geoEnd = payload.geoLocation;
        //console.log("state.local.nachtragTimer.trackedTimesEntries:",state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length-1].endTime);
      }
  }
  console.log("type: ",payload.type);
  let newEntry;
  //TODO: replace with real multilanguage strategy
  var label = {load:'Beladen',unload:'Entladen',drive:'Fahren',border:'Grenze',repair:'Service', pause:'Pause',stand:'Stehzeit',stop:'Stopp'};
  newEntry= {label:label[payload.type], type:payload.type, startTime:now+1, endTime:null, text:payload.text, km:payload.km, geoStart:payload.geoLocation};
  state.local.nachtragTimer.trackedTimesEntries.push(newEntry);
  if(payload.type !== 'stop'){  
    state.local.nachtragTimer.startTimeActualTimer = now/1000;
  }else{
    if(state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length -1] !== undefined){
        state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length -1].endTime=now+1;
        state.local.nachtragTimer.trackedTimesEntries[state.local.nachtragTimer.trackedTimesEntries.length -1].geoEnd=payload.geoLocation;
    }
    state.local.nachtragTimer.startTimeActualTimer = -1;
    //TODO: send status schould not be ok, because not sent... refactor, maybe in /check check if "time" for Bericht status
    state.local.curCheck.checklistStatus["time"].sendStatus = "sent";
    state.local.curCheck.checklistStatus["time"].fieldStatus = "ok";
  }
};
const clearTimers = (state) => {
  state.local.nachtragTimer.trackedTimesEntries = [];
};
const setCurTourId = (state,tourId) => {
  state.local.curCheck.curTourId = tourId;
};
/*const setOptionsPopupOld = (state, payload) => {
    if(payload !== undefined){
        if(payload.field && payload.options){
            state.status.optionsPopup.field = payload.field;
            state.status.optionsPopup.options = payload.options;
            state.status.optionsPopup.active = true;
            return;
        }
    }
    state.status.optionsPopup.active = false;
}*/
//refactor! only pass optionindex and get the option that should be shown directly from store
const setOptionsPopup = (state, payload) => {
    if(payload !== undefined){
        if(payload.field && payload.selectedOptionIndex !== undefined){
            state.status.optionsPopup.field = payload.field;
            state.status.optionsPopup.option = payload.selectedOptionIndex;
            state.status.optionsPopup.active = true;
            return;
        }
    }
    state.status.optionsPopup.active = false;
}
const setLockPopup = (state, payload) => {
    if(payload !== undefined){
        if(payload.field !== undefined){
            state.status.lockPopup.field = payload.field;
            state.status.lockPopup.type = payload.type;
            state.status.lockPopup.active = true;
            return;
        }
    }
    state.status.lockPopup.active = false;
}
const checkQrValue = (state,payload) => {
    if(payload.qrValue !== undefined){
        if(payload.field !== undefined){
            if(state.local.checkResultsNew[payload.field.formId]){
                if(state.local.checkResultsNew[payload.field.formId][payload.field.fieldId]){
                    if(state.local.checkResultsNew[payload.field.formId][payload.field.fieldId].qrValue === payload.qrValue){
                        state.local.checkResultsNew[payload.field.formId][payload.field.fieldId].lockedBy = "unlocked";
                        state.status.lockPopup.active = false;
                    }
                }
            }
        }
    }
}
const setFieldResult = (state, payload) => {
    /********
     * handle the actual field of of the payload
     */
    if(state.local.checkResultsNew[payload.formId]){
        if(state.local.checkResultsNew[payload.formId][payload.fieldId]){
            let field = state.local.checkResultsNew[payload.formId][payload.fieldId];
            //set the fields value and the selected=true in the option (options[trueOption]) 
            if(['radio','boolean'].includes(field.type.type)){
                if(field.options && field.options.length > 1){
                    if(['boolean'].includes(field.type.type)){
                        //set option with type "option" true, because it can't have a textvalue etc.
                        if(field.options[field.trueOption].value === null && field.options[field.trueOption].type === "option"){
                            field.options[field.trueOption].value = true;
                        }
                        field.selectedOption = field.trueOption;
                    }
                    if(payload.value === true){
                        field.options[field.trueOption].selected = true;
                        field.options[field.falseOption].selected = false;
                        field.selectedOption = field.trueOption;
                        field.value = field.trueOption;
                    }else if(payload.value === false){
                        //if both options have same severity, choose one
                        if(field.trueOption === field.falseOption){
                            field.falseOption = 0;
                            field.trueOption = 1;
                        }
                        field.options[field.falseOption].selected = true;
                        field.options[field.trueOption].selected = false;
                        field.selectedOption = field.falseOption;
                        field.value = field.falseOption;
                    }else{
                        state.local.checkResultsNew[payload.formId][payload.fieldId].value = payload.value;
                        if(['radio'].includes(field.type.type)){
                            field.selectedOption = payload.value;
                            field.options[payload.value].selected = true;
                            field.options.forEach((option,key) => {
                                if(key !== payload.value){
                                    option.selected = false;
                                }
                            })
                        }
                    }
                }else{
                    console.log("set field to: ",payload.value);
                }
            }else{
                state.local.checkResultsNew[payload.formId][payload.fieldId].value = payload.value;
            }
            if(payload.type !== 'group'){
                state.local.checkResultsNew[payload.formId][payload.fieldId].complete = true;
            }
        }else{
            console.log("state.local.checkResultsNew[payload.formId][payload.fieldId]: should not reach this. all fields should be initialized!")
        }
    }else{
        console.log("error at state.local.checkResultsNew[payload.formId]. should not reach this. all fields should be initialized!")
    }
    //check if other complete or values are to set
    let formComplete = true;
    let groupComplete = true;
    //groupTotalComplete is true when all fields are set, also the non required ones
    let groupTotalComplete = true;
    let res = true;
    /********
     * handle the groupcheck => check all children
     */ 
    //iterate all results
    Object.keys(state.local.checkResultsNew[payload.formId]).forEach((key) => {
        let elem = state.local.checkResultsNew[payload.formId][key];
        //if the payload field is a group, set all values of its children
        if(payload.type === 'group'){
            if(elem.parentId === payload.fieldId){
                if(payload.value === true){
                    //if radio buttons should automatically select the least severe option when parent is clicked => add here to array in if
                    if(['checkbox','boolean'].includes(elem.type.type)){
                        if(elem.options && elem.options.length > 1){
                            if(['boolean'].includes(elem.type.type)){
                                //set option with type "option" true, because it can't have a textvalue etc.
                                if(elem.options[elem.trueOption].value === null && elem.options[elem.trueOption].type === "option"){
                                    elem.options[elem.trueOption].value = true;
                                }
                            }
                            //set the selected property of the option to true
                            elem.options[elem.trueOption].selected = true;
                            //set the element value true, only used for displaying boolean
                            elem.value = elem.trueOption;
                        }else{
                            elem.value = true;
                        }
                        elem.complete = true;
                    }
                    if(['radio'].includes(elem.type.type)){
                        if(elem.options && elem.options.length > 1){
                            elem.options.forEach((option) => {
                                option.selected = false;
                            });
                            elem.value = "";
                        }else{
                            elem.value = "";
                        }
                        elem.complete = true;
                    }
                }else{
                    if(['checkbox','radio','boolean'].includes(elem.type.type)){
                        if(elem.options && elem.options.length > 1){
                            if(elem.options[elem.trueOption].value === null){
                                elem.options[elem.trueOption].value = true;
                            }
                            elem.options.forEach((option) => {
                                option.selected = false;
                            });
                            elem.value = "";
                        }else{
                            elem.value = "";
                        }
                        elem.complete = false;
                    }
                }
            }
        //if the field is a boolean, check if all siblings are allready set, if not -> complete is false
        //also check if one of the required values is false -> the group is false
        }else {
        //else if(payload.type === 'boolean'){
            if(elem.parentId === payload.parentId){
                if(elem.skip === false){
                    if(elem.complete !== true){
                        groupTotalComplete = false;
                        if(elem.required === true){
                            groupComplete=false;
                        }
                        //console.log("groupcomplete false on element: ",elem.fieldId, groupComplete, groupTotalComplete)
                    }
                    //can happen if all options are true
                    if(elem.falseOption !== elem.trueOption){
                        if((elem.type !== 'group' && elem.value === false) || elem.value === elem.falseOption){
                            res=false;
                            //console.log("res FALSE!!!: ",elem.parentId);
                            //console.log("res FALSE formId!!!: ",elem.fieldId);
                        }
                    }
                }
            }
        }
        
        //the formComplete is false as long as there is at least one (none skipped) required field not complete
        if(elem.skip === false){
            if(elem.complete !== true && elem.required){
                formComplete = false;
                //console.log("formComplete: ",formComplete)
            }
        }
    });
    /********
     * handle and set the group complete state itself (above only its children are set)
     */
    if(payload.type === 'group'){
        if(payload.value === true){
            state.local.checkResultsNew[payload.formId][payload.fieldId].complete = true;
        }else{
            state.local.checkResultsNew[payload.formId][payload.fieldId].complete = false;
        }
    //set the group itself to complete if the current field is the last field in the group
    }else{
        if(groupComplete === true){
            //console.log("groupcomplete")
            if(payload.type === 'boolean'){
                //only check it for fields that are inside a group and have a parentId
                if(state.local.checkResultsNew[payload.formId][payload.parentId] !== undefined){
                    state.local.checkResultsNew[payload.formId][payload.parentId].value = res;
                    state.local.checkResultsNew[payload.formId][payload.parentId] = {...state.local.checkResultsNew[payload.formId][payload.parentId], ...{complete:true}};
                }
            }
            if(groupTotalComplete === true){
                //console.log("groupTotalComplete")
                state.local.checkResultsNew[payload.formId][payload.parentId] = {...state.local.checkResultsNew[payload.formId][payload.parentId], ...{complete:true}};
            }
        }else{
            state.local.checkResultsNew[payload.formId][payload.parentId].complete = false;
        }
    }

    /*****
     * handle the form state
     */
    state.local.curCheck.checklistStatus[payload.formId].sendStatus = 'open';
    if(formComplete && res){
        state.local.curCheck.checklistStatus[payload.formId].fieldStatus = 'ok'
    }else if(formComplete && !res){
        console.log("RADIO fieldStatus failed")
        state.local.curCheck.checklistStatus[payload.formId].fieldStatus = 'failed'
    }
    else{
        state.local.curCheck.checklistStatus[payload.formId].fieldStatus = 'partial'
    }
};


//boolean fields can have additional fields (text or document options), when false is choosen 
const setFieldResultOld = (state, payload) => {
    //set value and complete state when field exists
    if(state.local.checkResultsNew[payload.formId]){
        if(state.local.checkResultsNew[payload.formId][payload.fieldId]){
            state.local.checkResultsNew[payload.formId][payload.fieldId].value = payload.value;
            if(payload.type !== 'group'){
                state.local.checkResultsNew[payload.formId][payload.fieldId].complete = true;
            }
        }else{
            console.log("state.local.checkResultsNew[payload.formId][payload.fieldId]: should not reach this. all fields should be initialized!")
        }
    }else{
        console.log("error at state.local.checkResultsNew[payload.formId]. should not reach this. all fields should be initialized!")
    }

    //check if other complete or values are to set
    let formComplete = true;
    let groupComplete = true;
    //groupTotalComplete is true when all fields are set, also the non required ones
    let groupTotalComplete = true;
    let res = true;
    //iterate all results
    Object.keys(state.local.checkResultsNew[payload.formId]).forEach((key) => {
        let elem = state.local.checkResultsNew[payload.formId][key];
        //if the field is a group, set all values of its children
        if(payload.type === 'group'){
            if(elem.parentId === payload.fieldId){
                if(payload.value === true){
                    //for the value only consider boolean a nd checkbox children of the payload groupfield
                    //but complete them all
                    if(elem.parentId === payload.fieldId){
                        if(elem.type.type === 'checkbox'){
                            elem.value = true;
                            if(elem.options){
                                elem.options.forEach((option) => {
                                    option.value = true;
                                });
                            }
                        }else if(elem.type.type === 'boolean'){
                            elem.value = true;
                        }
                        elem.complete = true;
                    }
                }else{
                    if(elem.type.type === 'checkbox'){
                        elem.value = '';
                        if(elem.options.length <= 0){
                            elem.value = '';
                        }else if(elem.options){
                            elem.options.forEach((option) => {
                                option.value = false;
                            });
                        }
                    }else if(elem.type.type === 'boolean'){
                        elem.value = '';
                        elem.complete = false;
                    }
                    
                }
            }
        //if the field is a boolean, check if all siblings are allready set, if not -> complete is false
        //also check if one of the required values is false -> the group is false
        }else if(payload.type === 'boolean'){
            if(elem.parentId === payload.parentId){
                if(elem.complete !== true){
                    groupTotalComplete = false;
                    if(elem.required === true){
                        groupComplete=false;
                    }
                    console.log("groupcomplete false on element: ",elem.fieldId)
                }
            }  
        }else{
            if(elem.parentId === payload.parentId){
                if(elem.complete !== true){
                    groupTotalComplete = false;
                    if(elem.required === true){
                        groupComplete=false;
                        console.log("groupcomplete false on NONE boolean element: ",elem.fieldId)
                    }
                }
            }  
        }
        if(elem.value === false){
            res=false;
            console.log("res FALSE!!!: ",elem.parentId);
        }
        //the formComplete is false as long as there is at least one required field not complete
        if(elem.complete !== true && elem.required === true){
            formComplete = false;
            console.log("formComplete: ",formComplete)
        }
    });
    //set the group complete state itself (above only its children are set)
    if(payload.type === 'group'){
        if(payload.value === true){
            state.local.checkResultsNew[payload.formId][payload.fieldId].complete = true;
        }else{
            state.local.checkResultsNew[payload.formId][payload.fieldId].complete = false;
        }
    //set the group itself to complete if the current field is the last field in the group
    }else{
        if(groupComplete === true){
            console.log("groupcomplete")
            if(payload.type === 'boolean'){
                state.local.checkResultsNew[payload.formId][payload.parentId].value = res;
                state.local.checkResultsNew[payload.formId][payload.parentId] = {...state.local.checkResultsNew[payload.formId][payload.parentId], ...{complete:true}};
            }
            if(groupTotalComplete === true){
                console.log("groupTotalComplete")
                state.local.checkResultsNew[payload.formId][payload.parentId] = {...state.local.checkResultsNew[payload.formId][payload.parentId], ...{complete:true}};
            }
        }else{
            state.local.checkResultsNew[payload.formId][payload.parentId].complete = false;
        }
    }

    state.local.curCheck.checklistStatus[payload.formId].sendStatus = 'open';
    if(formComplete && res){
        state.local.curCheck.checklistStatus[payload.formId].fieldStatus = 'ok'
    }else if(formComplete && !res){
        state.local.curCheck.checklistStatus[payload.formId].fieldStatus = 'failed'
    }
    else{
        state.local.curCheck.checklistStatus[payload.formId].fieldStatus = 'partial'
    }
};
const setFieldOptionResult = (state, payload) => {
    //set value and complete state when field exists
    //payload:{'formId':this.formId,'fieldId':this.fieldId,'value':this.initialValues, 'parentId':this.parentId, type:'text'}
    if(state.local.checkResultsNew[payload.formId]){
        if(state.local.checkResultsNew[payload.formId][payload.fieldId]){
            state.local.checkResultsNew[payload.formId][payload.fieldId].options[payload.optionKey].value = payload.value;
            //state.local.checkResultsNew[payload.formId][payload.fieldId].options[payload.optionKey].fileUrl = payload.fileUrl;
        }
        if(payload.fileObject !== undefined){
            console.log("fileObject in mutation:",payload.fileObject)
            if(Array.isArray(state.local.checkResultsNew[payload.formId][payload.fieldId].options[payload.optionKey].fileObjects)){
                state.local.checkResultsNew[payload.formId][payload.fieldId].options[payload.optionKey].fileObjects.push({"fileObject":payload.fileObject, "status":"unsent"});
            }else{
                state.local.checkResultsNew[payload.formId][payload.fieldId].options[payload.optionKey].fileObjects = [];
                state.local.checkResultsNew[payload.formId][payload.fieldId].options[payload.optionKey].fileObjects.push({"fileObject":payload.fileObject, "status":"unsent"});
            }
        }
    }
};
const deleteOptionFile = (state, payload) => {
    if(state.local.checkResultsNew[payload.formId]){
        if(state.local.checkResultsNew[payload.formId][payload.fieldId]){
            if(Array.isArray(state.local.checkResultsNew[payload.formId][payload.fieldId].options[payload.optionKey].fileObjects)){
                if(state.local.checkResultsNew[payload.formId][payload.fieldId].options[payload.optionKey].fileObjects[payload.fileKey]){
                    state.local.checkResultsNew[payload.formId][payload.fieldId].options[payload.optionKey].fileObjects[payload.fileKey].status = "deleted";
                }
            }
        }
    }
};
const setServerFileId = (state, payload) => {
    if(state.local.checkResultsNew[payload.formId]){
        if(state.local.checkResultsNew[payload.formId][payload.fieldId]){
            //let newEntry = {[payload.localFileId] : payload.serverFileId};
            //state.local.checkResultsNew[payload.formId][payload.fieldId].options[payload.optionKey].serverFileIds = {...state.local.checkResultsNew[payload.formId][payload.fieldId].options[payload.optionKey].serverFileIds,...newEntry};
            state.local.checkResultsNew[payload.formId][payload.fieldId].options[payload.optionKey].fileObjects[payload.localFileId].serverFileId = payload.serverFileId;
            state.local.checkResultsNew[payload.formId][payload.fieldId].options[payload.optionKey].fileObjects[payload.localFileId].status = "sent";
        }
    }
};
const auth_request = (state) => {
  state.status.value = 'loading'
};
const auth_success = (state, tokens) => {
  state.status.value = 'success'
  state.tokens.accessToken = tokens.accessToken
  if(tokens.refreshToken){
    state.tokens.refreshToken = tokens.refreshToken
  }
  if (state.tokens.accessToken) {
    state.isLoggedIn = true;
    HTTP.defaults.headers.common['authorization'] = "Bearer "+state.tokens.accessToken;
    HTTPFILE.defaults.headers.common['authorization'] = "Bearer "+state.tokens.accessToken;
  } else {
    HTTP.defaults.headers.common['authorization'] = null;
    HTTPFILE.defaults.headers.common['authorization'] = null;
  }
};
const auth_reset_at_refresh = (state) => {
  if (state.tokens.accessToken) {
    HTTP.defaults.headers.common['authorization'] = "Bearer "+state.tokens.accessToken;
    HTTPFILE.defaults.headers.common['authorization'] = "Bearer "+state.tokens.accessToken;
  } else {
    HTTP.defaults.headers.common['authorization'] = null;
    HTTPFILE.defaults.headers.common['authorization'] = null;
  }
};
const auth_error = (state) => {
  state.status.value = 'error'
  state.tokens.accessToken = ''
  state.isLoggedIn = false;
  HTTP.defaults.headers.common['authorization'] = null;
  HTTPFILE.defaults.headers.common['authorization'] = null;
};
const api_error = (state) => {
  state.status.value = 'error'
  //HTTP.defaults.headers.common['authorization'] = null;
};
const refreshToken = (state, response) => {
  state.tokens.accessToken = response.accessToken;
};
const update_me = (state,profileData) => {
    state.user = {...state.user,...profileData};
    let firstName = [];
    let lastName = [];
    let idNr = [];
    let idValidDate = [];
    
    
    let persData;
    let persId;
    //let persSettings;
    if(profileData.meta.groups !== undefined){
        //get the metagroups by key
        persData = profileData.meta.groups.filter(obj => {
            return obj.key === "persData";
        });
        persId = profileData.meta.groups.filter(obj => {
            return obj.key === "persId";
        });
        /*persSettings = profileData.meta.groups.filter(obj => {
            return obj.key="persSettings";
        });*/

        //store the meta fields in vars
        if(persData[0] !== undefined){
            firstName = persData[0].fields.filter(obj => {
                return obj.meta_key === 'firstname'
            });
            lastName = persData[0].fields.filter(obj => {
                return obj.meta_key === 'lastname'
            });
            idNr = persId[0].fields.filter(obj => {
                return obj.meta_key === 'idNr'
            });
            idValidDate = persId[0].fields.filter(obj => {
                return obj.meta_key === 'idDate'
            });
        }
    }
    console.log("firstName: ",firstName);
    console.log("lastName: ",lastName);
    state.user.lastName = lastName[0] !== undefined && lastName[0].meta_value !== null ? lastName[0].meta_value : "";
    state.user.firstName = firstName[0] !== undefined && firstName[0].meta_value !== null ? firstName[0].meta_value : "";
    state.user.idNr = idNr[0] !== undefined && idNr[0].meta_value !== null ? idNr[0].meta_value : "";
    state.user.idValidDate = idValidDate[0] !== undefined && idValidDate[0].meta_value !== null ? idValidDate[0].meta_value : null;

    //TODO: this is just a temproary hack to disable timetracking via company metafield, later use features array provided by API
    let companyData;
    let disableTimer;
    let enableToDos;
    if(profileData.company.meta.groups !== undefined){
        //get the metagroups by key
        companyData = profileData.company.meta.groups.filter(obj => {
            return obj.key === "tmpAppFeatures";
        });
    }
    //store the meta fields in vars
    if(companyData[0] !== undefined){
        disableTimer = companyData[0].fields.filter(obj => {
            return obj.meta_key === 'disableTimer'
        });
        enableToDos = companyData[0].fields.filter(obj => {
            return obj.meta_key === 'enableToDos'
        });
    }
    //console.log("companyData: ",companyData);
    if(disableTimer !== undefined && disableTimer[0] !== undefined){
        //console.log("disableTimer: ",disableTimer[0].meta_value);
        if(disableTimer[0].meta_value === true){
            state.features.featureArray = state.features.featureArray.filter(function(value){ 
                return value !== 'time';
            });
        }
    }
    if(enableToDos !== undefined && enableToDos[0] !== undefined){
        console.log("enableToDos: ",enableToDos[0].meta_value);
        if(enableToDos[0].meta_value === false){
            state.features.featureArray = state.features.featureArray.filter(function(value){ 
                return value !== 'toDo';
            });
        }
    }
};
const update_vehiclelist = (state, vehicleList) => {
  if(vehicleList){
    var lkwList = vehicleList.filter(obj => {
      return obj.is_trailer === 0
    })
    if(lkwList){
      lkwList.forEach((lkw)=>{
        let newEntry = { [lkw.vehicle_id] : lkw }
        state.vehiclesAPI.vehicles = {...state.vehiclesAPI.vehicles, ...newEntry}
      });
    }
  }
};
const update_trailerlist = (state, trailerList) => {
  let newEntry = { [0] : {vehicle_id:0,license_plate:"KEIN HÄNGER"}};
  state.vehiclesAPI.trailers = {...state.vehiclesAPI.trailers, ...newEntry}
  if(trailerList){
    trailerList.forEach((trailer)=>{
      newEntry = { [trailer.vehicle_id] : trailer }
      state.vehiclesAPI.trailers = {...state.vehiclesAPI.trailers, ...newEntry}
    });
  }
};
const update_tourlist = (state, tourList) => {
  if(tourList){
    state.local.documentation.totalEntries = tourList.pagination.total;
    state.local.documentation.pageCount = tourList.pagination.pages;
    let newEntry = { [tourList.pagination.page] : tourList.rows }
    state.local.documentation.finishedChecks = {...state.local.documentation.finishedChecks, ...newEntry};
    tourList.rows.forEach(function(tour,index){
        var tourMaxSeverity = -1;
        if(Array.isArray(tour.error_status) && tour.error_status !== null && tour.error_status !== undefined){
            console.log("error_status: ",tour.error_status);
            tour.error_status.forEach(function(error){
                if(error.severity){
                    error.severity.forEach(function(severity){
                        if(severity.severity > tourMaxSeverity){
                            tourMaxSeverity = severity.severity;
                        }
                    })
                }
            })
        }
        state.local.documentation.finishedChecks[tourList.pagination.page][index] = {...state.local.documentation.finishedChecks[tourList.pagination.page][index], ...{status:tourMaxSeverity}};
    });
  }
};
const update_tourdetails = (state, tourDetails) => {
    if(tourDetails){
        state.local.documentation.tourDetails.submissions = tourDetails.submissions;
        state.local.documentation.tourDetails.vehicle = tourDetails.vehicle;
        state.local.documentation.tourDetails.secondaryVehicles = tourDetails.secondary_vehicles;
        state.local.documentation.tourDetails.timers = tourDetails.timers;
    }
};
const set_active_doc_page = (state, page) => {
  state.local.documentation.activePage = page;
};
const add_checklist = (state,cecklistData) => {
  if(cecklistData){
    let newEntry = { [cecklistData.form_id] : cecklistData }
    console.log("newEntry checklist form: ",newEntry);
    state.local.checkLists = { ...state.local.checkLists, ...newEntry }
    let newStatusEntry = {[cecklistData.form_id] : {fieldStatus:'open', sendStatus:'open'}}
    state.local.curCheck.checklistStatus = { ...state.local.curCheck.checklistStatus, ...newStatusEntry }
  }
};
const resetChecklistStatus = (state, formId) => {
    state.local.curCheck.checklistStatus[formId] = {fieldStatus:"open", sendStatus:"open"}
};
const initCheckResults = (state, payload) => {
    //generate the structure for the checkResultNew object
    console.log("initCheckResults");
    let newEntry;
    if(payload){
        if(state.local.checkResultsNew[payload.form_id] === undefined){
            newEntry = {[payload.form_id] :{}};
            state.local.checkResultsNew = {...state.local.checkResultsNew, ...newEntry};
        }
        payload.form_fields.forEach((field)=>{
            var label = '';
            var name = '';
            let truestOptionIndex = 0;
            let falsestOptionIndex = 0;
            let minSeverity = Infinity;
            let maxSeverity = 0;
            let value;
            let type;
            //TODO: remove -> temporarily made a field QR locked to test
            /*if(payload.form_slug === "abfahrtskontrolle"){

                if([3].includes(field.form_field_id)){
                    lockedBy = "qr";
                    qrValue = "Abblendlicht LKW B555";
                }
            }*/
            if(field.field_label){
                label = field.field_label;
            }
            if(field.field_name){
                name = field.field_name.value;
            }
            if(field.field_type){
                type = field.field_type;
            }
            //value of the field itself is a array of the selected options, here i check all these options that are the default value -> checked
            if(field.options !== null && Array.isArray(field.options) && field.options.length > 0){
                value = [];
                field.options.map((elem,index) => {
                    //initialize the fileUrl property, so it will later be reactive...
                    /*if(elem.type === "file"){
                        Vue.set(elem, 'fileUrl', "")
                    }*/
                    if(elem.type === "file"){
                        Vue.set(elem, 'fileObjects', [])
                    }
                    if(elem.isDefault === true){
                        elem.selected = true;
                        value.push(index);
                        //console.log("field label: ", field.label[0].value);
                        console.log("value: ", value);
                    }else{
                        elem.selected = false;
                    }
                    return elem;
                });
                var i;
                for (i = 0; i < field.options.length; i++) {
                    if(field.options[i].severity < minSeverity){
                        minSeverity = field.options[i].severity;
                        truestOptionIndex = i;
                    }
                }
                var n;
                for (n = 0; n < field.options.length; n++) {
                    if(field.options[n].severity > maxSeverity){
                        maxSeverity = field.options[n].severity;
                        falsestOptionIndex = n
                    }
                }
                //get the "true" and "false" Value of the field from the severity of the option
                //,4 is just a default value so that reduce works with empty arrays
                /*truestOptionIndex = field.options.reduce(function(prev, curr, index) {
                    return prev.severity < curr.severity ? {...prev,...{option:index-1}} : {...curr,...{option:index}}
                },4).option;
                falsestOptionIndex = field.options.reduce(function(prev, curr, index) {
                    return prev.severity > curr.severity ? {...prev,...{option:index-1}} : {...curr,...{option:index}}
                },0).option;*/
            }else{
                value = field.field_value;
                console.log("value no options: ", value);
            }
            newEntry = {[field.form_field_id]: {
                name:name,
                label:label, 
                parentId:field.parent_id, 
                fieldId:field.form_field_id, 
                level:field.level, 
                type:type, 
                value:value,
                trueOption:truestOptionIndex,
                falseOption:falsestOptionIndex,
                selectedOption:-1,
                options:field.options,
                required:field.required || false, 
                complete:false,
                lockedBy:field.codelock || false,
                qrValue:field.form_code,
                skip:field.skip || false,
            }};
            state.local.checkResultsNew[payload.form_id] = {...state.local.checkResultsNew[payload.form_id], ...newEntry};
        })
    }
};
const saveSentDefect = (state,sentDefect) => {
    let fields = [];
    console.log("sentDefect:",sentDefect);
    var defectExists = false;
    Object.keys(sentDefect).forEach((key) => {
        let field = sentDefect[key];
        defectExists = false;
        if(field.value !== null && field.value !== undefined && field.value !== ""){
            defectExists = true;
            if(["radio","boolean"].includes(field.type.type)){
                if(field.options !== undefined && field.options.length > 0){
                    //language!
                    if(field.options[field.value] !== undefined){
                        fields.push({label:field.label,value:field.options[field.value].label[0].value});
                    }
                }
            }else if(field.type.type === "text"){
                //language!
                fields.push({label:field.label,value:field.value});
            }
        }
    });
    console.log("fields",fields);
    if(defectExists === true && fields.length > 0){
        state.local.sentDefects.push(fields);
    }
};
const set_checklistId = (state,data) => {
  if(data){
    console.log("set_checklistId data: ",data)

    if(data.form_base_id == state.local.curCheck.checkListId[0].baseId){
        state.local.curCheck.checkListId[0].formId = data.form_id;
    }
    if(data.form_base_id == state.local.curCheck.defectsId.baseId){
        state.local.curCheck.defectsId.formId = data.form_id;
    }
    if(data.form_base_id == state.local.curCheck.endId.baseId){
        state.local.curCheck.endId.formId = data.form_id;
    }
    if(data.form_base_id == state.local.curCheck.toDoId.baseId){
        state.local.curCheck.toDoId.formId = data.form_id;
        console.log("set toDoId!! ", data.form_id);
    }
    state.initialized = true;
    //if only temporary, should later be decided from api which kind of list it is (depending on vehicle)
    /*if(data.form_slug == 'abfahrtskontrolle'){
      state.local.curCheck.checkListId.formId = data.form_id;
    }
    if(data.form_slug == 'maengelliste'){
      state.local.curCheck.defectsId.formId = data.form_id;
    }
    if(data.form_slug == 'endkontrolle'){
      state.local.curCheck.endId.formId = data.form_id;
    }*/
  }
};
//TODO: push new messages into array, handle them one after another and show then for the specific time
const set_status_msg = (state, status) => {
  if(status){
    state.status.footerMsg.text = status.text;
    state.status.footerMsg.type = status.type;
  }
};
const set_status_msg_active = (state, active) => {
  state.status.footerMsg.active = active;
};
const finishCurCheck = (state) => {
    //var dateFormat = require('dateformat');
    let d = new Date();
    state.local.curCheck = {
        lkwDataId:'lkw',
        timeId:'time',
        checkListId:[{
            formId:-1,
            baseId:-1,
        }],
        defectsId:{
            formId:-1,
            baseId:-1,
        },
        endId:{
            formId:-1,
            baseId:-1,
        },
        toDoId:{
            formId:-1,
            baseId:-1,
        },
        startTime:d,
        chosenTruck: -1,
        chosenTrailer: -1,
        curTourId: -1,
        checklistStatus:{
            lkw:{fieldStatus:'open', sendStatus:'open'},
            time:{fieldStatus:'open', sendStatus:'open'}
        },
    };
    state.local.checkResultsNew = {};
    state.local.sentDefects = [];
};
const deleteAccessToken = (state) => {
  state.tokens.accessToken = '';
};
const logout = (state, keepData) => {
  if(keepData){
    state.keepData = true;
  }else{
    state.keepData = false;
  }
  state.isLoggedIn = false;
  state.status.value = '';
  state.tokens.accessToken = '';
  HTTP.defaults.headers.common['authorization'] = null;
  HTTPFILE.defaults.headers.common['authorization'] = null;
};
const setRefreshCount = (state,clear) => {
  if(clear){
    state.status.refreshCount = 0;
  }else{
    state.status.refreshCount = state.status.refreshCount +1;
  }  
};

const setAdress = (state, adress) => {
    state.local.nachtragTimer.country = adress.countryCode;
    state.local.nachtragTimer.city = adress.city;
};
const unSetAdress = (state) => {
    state.local.nachtragTimer.country = undefined;
};

export default{
    setRefreshCount,
    logout,
    deleteAccessToken,
    finishCurCheck,
    set_status_msg_active,
    set_checklistId,
    add_checklist,
    resetChecklistStatus,
    initCheckResults,
    saveSentDefect,
    set_status_msg,
    update_trailerlist,
    update_vehiclelist,
    update_tourlist,
    update_tourdetails,
    set_active_doc_page,
    update_me,
    auth_error,
    api_error,
    refreshToken,
    auth_reset_at_refresh,
    auth_success,
    auth_request,
    setOptionsPopup,
    setLockPopup,
    checkQrValue,
    setFieldResultOld,
    setFieldResult,
    setFieldOptionResult,
    setCurTourId,
    clearTimers,
    trackTime,
    setFormStatusSend,
    setFormStatusFields,
    setTimerId,
    setType,
    setTrailer,
    setTruck,
    setCurCheckStartTime,
    toggleNav,
    loadFromCache,
    setAdress,
    unSetAdress,
    setServerFileId,
    deleteOptionFile,
}