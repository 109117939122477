var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('appheader',{attrs:{"headline":"Home","rowOne":{
        userName:_vm.fullName,
        company:this.$store.state.user.company
    },"rowTwo":{
        colOne:{
            head:_vm.$t("header.id"),
            content:this.$store.getters.getDaysRemaining("training"),
            foot:_vm.$t("header.valid"),
        },
        colTwo:{
            head:_vm.$t("header.driversLicense"),
            content:this.$store.getters.getDaysRemaining("license"),
            foot:_vm.$t("header.valid")
        },
        colThree:{
            head:_vm.$t("header.driverCard"),
            content:this.$store.getters.getDaysRemaining("card"),
            foot:_vm.$t("header.valid")
        },
        onClick:this.toggleC95,
    },"rowThree":{
        active:false,
        colOne:{
        head:this.$store.state.user.posDriveChecks,
        status:1
        },
        colTwo:{
        head:this.$store.state.user.neutralDriveChecks,
        status:2,
        },
        colThree:{
        head:this.$store.state.user.negDriveChecks,
        status:3,
        foot:""
        }
    }}}),_c('drop-down-generic',{attrs:{"active":this.c95Visible,"title":"title","text":"text"},nativeOn:{"click":function($event){return _vm.toggleC95()}}},[_c('div',{staticClass:"content-wrap dropdown"},[_c('div',{staticClass:"license-header"},[_c('span',{staticClass:"license license-col"},[_vm._v(_vm._s(_vm.$t("header.license.license")))]),_c('span',{staticClass:"validity license-col"},[_vm._v(_vm._s(_vm.$t("header.valid")))]),_c('span',{staticClass:"next-lesson license-col"},[_vm._v(_vm._s(_vm.$t("header.license.nextLesson")))])]),_vm._l((this.$store.getters.getLicenses(true)),function(entry,index){return _c('div',{key:index,staticClass:"license-row"},[_c('span',{staticClass:"license license-col "},[_vm._v(_vm._s(entry.license_type))]),_c('div',{staticClass:"validity license-col"},[_c('div',[(entry.valid_from !== null)?_c('div',[_vm._v(_vm._s(_vm.$t("header.license.from")+" "+entry.valid_from))]):_vm._e(),(entry.valid_to !== null)?_c('div',[_vm._v(_vm._s(_vm.$t("header.license.to")+" "+entry.valid_to))]):_vm._e()])]),_c('div',{staticClass:"next-lesson license-col"},[_vm._v(" "+_vm._s(entry.training_date)+" ")])])})],2)]),_c('div',{staticClass:"content-wrap"},[(_vm.tourActive)?_c('bignavicon',{attrs:{"imgSrc":_vm.getImgUrl("Abfahrtskontrolle.svg"),"link":"/check","text":_vm.$t("navigation.tour")}}):_c('bignavicon',{attrs:{"imgSrc":_vm.getImgUrl("Abfahrtskontrolle.svg"),"link":"/check/lkw","text":_vm.$t("navigation.startTour")}}),_c('bignavicon',{attrs:{"imgSrc":_vm.getImgUrl("Dokumentation.svg"),"link":"/docu","text":_vm.$t("navigation.documentation")}}),_c('bignavicon',{attrs:{"imgSrc":_vm.getImgUrl("Abfahrk_pers_Daten.svg"),"link":"/personaldata","text":_vm.$t("navigation.personalData")}})],1),_c('appfooter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }