import localForage from 'localforage';

const store = localForage.createInstance({
  name: 'app',
});
store.setDriver(store.INDEXEDDB);

const mapStateForCache = (state) => {
  let filteredState = {...state};
  delete filteredState.isNavOpen;
  //delete filteredState.tokens.accessToken;
  //delete filteredState.tokens.refreshToken;
  //console.log("filteredState: ",filteredState);
  return filteredState;
};

export const setState = async (state) => {
  const cachedState = mapStateForCache(state);
  //console.log("cachedState: ",cachedState);
  return await store.setItem('state', cachedState);
};

export const getState = () => store.getItem('state');

export const deleteLocalForageInstance = () => {
  console.log("store: ",store)
  console.log("deleteState!!!!")
  var response = store.dropInstance({name: 'app'})
  console.log("response:" ,response)
  return response;
}

export const deleteState = () => store.removeItem('state');