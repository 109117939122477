<template>
    <div class="home">
        <appheader 
        headline="Home"
        v-bind:rowOne='{
            userName:fullName,
            company:this.$store.state.user.company
        }'
        v-bind:rowTwo='{
            colOne:{
                head:$t("header.id"),
                content:this.$store.getters.getDaysRemaining("training"),
                foot:$t("header.valid"),
            },
            colTwo:{
                head:$t("header.driversLicense"),
                content:this.$store.getters.getDaysRemaining("license"),
                foot:$t("header.valid")
            },
            colThree:{
                head:$t("header.driverCard"),
                content:this.$store.getters.getDaysRemaining("card"),
                foot:$t("header.valid")
            },
            onClick:this.toggleC95,
        }'
        v-bind:rowThree='{
            active:false,
            colOne:{
            head:this.$store.state.user.posDriveChecks,
            status:1
            },
            colTwo:{
            head:this.$store.state.user.neutralDriveChecks,
            status:2,
            },
            colThree:{
            head:this.$store.state.user.negDriveChecks,
            status:3,
            foot:""
            }
        }'
        ></appheader>
        <drop-down-generic :active="this.c95Visible" title="title" text="text" v-on:click.native="toggleC95()">
            <div class="content-wrap dropdown">
                <div class="license-header">
                    <span class="license license-col">{{$t("header.license.license")}}</span>
                    <span class="validity license-col">{{$t("header.valid")}}</span>
                    <span class="next-lesson license-col">{{$t("header.license.nextLesson")}}</span>
                </div>
                <div class="license-row" v-for='(entry,index) in this.$store.getters.getLicenses(true)' :key="index">
                    <span class="license license-col ">{{entry.license_type}}</span>
                    <div class="validity license-col">
                        <div>
                            <div v-if="entry.valid_from !== null">{{$t("header.license.from")+" "+entry.valid_from}}</div>
                            <div v-if="entry.valid_to !== null">{{$t("header.license.to")+" "+entry.valid_to}}</div>
                        </div>
                    </div>
                    <div class="next-lesson license-col">
                        {{entry.training_date}}
                    </div>
                </div>
                
            </div>
        </drop-down-generic>
        <div class="content-wrap">
        <bignavicon v-if="tourActive" :imgSrc='getImgUrl("Abfahrtskontrolle.svg")' link="/check" :text='$t("navigation.tour")'></bignavicon>
        <bignavicon v-else :imgSrc='getImgUrl("Abfahrtskontrolle.svg")' link="/check/lkw" :text='$t("navigation.startTour")'></bignavicon>
        <bignavicon :imgSrc='getImgUrl("Dokumentation.svg")' link="/docu" :text='$t("navigation.documentation")'></bignavicon>
        <bignavicon :imgSrc='getImgUrl("Abfahrk_pers_Daten.svg")' link="/personaldata" :text='$t("navigation.personalData")'></bignavicon>
        </div>
        <appfooter></appfooter>
    </div>
</template>

<script>
import DropDownGeneric from "@/components/FormFields/DropDownGeneric.vue";
export default {
    data:function () {
        return {
            c95Visible:false,
        }
    },
    methods:{
        getImgUrl(img) {
            return require('../assets/view-home/'+img)
        },
        toggleC95(){
            if(this.c95Visible==false){
                this.c95Visible = true;
                console.log("expand");
            }
            else{
                this.c95Visible = false;
                console.log("collapse");
            }
        },
    },
    computed:{
        fullName:function(){
        if(this.$store.state.user.firstName || this.$store.state.user.lastName){
            return this.$store.state.user.firstName + " " + this.$store.state.user.lastName
        }
        return this.$store.state.user.username;
        },
        tourActive(){
            return this.$store.state.local.curCheck.curTourId >= 0;
        },
    },
    beforeCreate() {
        console.log("beforeCreate");
        this.$store.dispatch('getProfileData');
    },
    components: {
        'drop-down-generic':DropDownGeneric
    },
};
</script>

<style scoped lang='scss'>
.content-wrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding:5%;
    margin-bottom:60px;
    .license-row, .license-header{
        text-align: left;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        gap: 0;
        .license-col{
            flex-basis: 33%;
            padding: 5px;
        }
        .license{
            flex-basis: 20%;
        }
        .validity{
            flex-basis: 45%;
        }
        .next-lesson{
            flex-basis: 35%;
            text-align: right;
        }
    }
    .license-header{
        font-weight: 600;
    }
    .license-row{
        border-bottom: 1px solid black;
    }
}
.home .nav-icon-wrap{
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 150px;
    min-height: 100px;
}
</style>
