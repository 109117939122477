import { deleteLocalForageInstance } from './storage';
import {HTTP, HTTPGEO, HTTPFILE} from '../helpers/axiosHelper.js';


const login = ({ commit }, credentials) => {
    commit('setRefreshCount',true);
    var urlencoded = new URLSearchParams();
    urlencoded.append("username", credentials.username);
    urlencoded.append("password", credentials.password);
    urlencoded.append("device", credentials.device);
    urlencoded.append("company", credentials.company);
    commit('auth_request')
    return HTTP({
        method: 'post',
        url: '/auth/login/driver',
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        data: urlencoded
    }).then(response => {
        const tokens = {accessToken: response.data.accessToken, refreshToken: response.data.refreshToken}
        commit('auth_success', tokens)
    }).catch(error => {
        console.log(error);
        commit('auth_error')
    });
};
const logout = ({commit}) => {
    commit('logout')
    return deleteLocalForageInstance().then(() => {
        //commit('logout')
        //TODO!!! check why the reload is necessary to delete the store. is there a racecondition???
        console.log("deleteLocalForageInstance resolves")
        location.reload();
        
    }).catch(error => {
        console.log("error in logout action: ",error);
    });

    //return new Promise((resolve) => {
    /*var response = indexedDB.deleteDatabase('app')
    
    response.onsuccess =  function(event){
        console.log("db successfully deleted: ",event);
        resolve()
    }
    response.onerror = function(event){
        console.log(event.result);
        resolve()
    }*/
    /*commit('logout')
    delete HTTP.defaults.headers.common['Authorization']
    deleteLocalForageInstance()
    //TODO!!! check why the reload is necessary to delete the store. is there a racecondition???
    location.reload();
    resolve()*/
    //localStorage.removeItem('access_token')
    //})
};
const setStatusMsg = ({commit},status) => {
    if(status){
        commit("set_status_msg",status.values);
        commit("set_status_msg_active",true);
        var time
        if(status.time){
            time = status.time;
        }else{
            time = 3000;
        }
        setTimeout(function(){
            commit("set_status_msg_active",false); 
        }, time);
    }
};
const refreshToken = ({commit, dispatch, state }) => {
    //count refresh tries so token refresh does not run into endless loop
    commit('setRefreshCount', false);
    return HTTP({
        method: 'post',
        url: '/auth/refresh',
        data: {"refresh_token":state.tokens.refreshToken},
        redirect: 'follow',
    }).then(response => {
        commit('refreshToken', response.data)
        commit('auth_success', response.data)
        commit('setRefreshCount', true);
    }).catch(error => {
        console.log("error in refreshToken Action: ",error);
        dispatch('setStatusMsg',{values:{text:'Authentifizierung beim Server fehlgeschlagen, Bitte neu einloggen.', type:'warning'},time:3000});
        commit('logout',false);
    });
};
const getProfileData = ({commit, dispatch}) => {
    return HTTP({
    method: 'get',
        url: '/me',
        redirect: 'follow',
    }).then(response => {
        commit('update_me', response.data)
    }).catch(error => {
        console.log(error);
        //commit('api_error')
        dispatch('setStatusMsg',{values:{text:'API Fehler, Progfildaten konnen nicht heruntergeladen werden.', type:'error'},time:3000});
    });
};
const getChecklists = ({commit, dispatch}, id) => {
    return HTTP({
        method: 'get',
        //url: '/driver/forms/base/'+id,
        url: '/driver/company/vehicles/'+id+'/forms',
        redirect: 'follow',
    }).then(response => {
        console.log("getChecklists response.data: ",response.data)
        response.data.forEach(form => {
            commit('add_checklist', form)
            commit('set_checklistId', form)
            commit('initCheckResults', form)
        })
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:'API Fehler, Formulardaten konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const getVehiclelist = ({commit, dispatch}) => {
    return HTTP({
        method: 'get',
        url: '/driver/company/vehicles',
        redirect: 'follow',
    }).then(response => {
        commit('update_vehiclelist', response.data)
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:'API Fehler, Fahrzeugdaten konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const getTrailerlist = ({commit, dispatch}) => {
    return HTTP({
        method: 'get',
        url: '/driver/company/trailers',
        redirect: 'follow',
    }).then(response => {
        commit('update_trailerlist', response.data)
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:'API Fehler, Anhängerdaten konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const getTourList = ({commit,dispatch},page) => {
    //returns paginated tours by driver
    return HTTP({
        method: 'get',
        url: '/driver/tours/list?limit=10&page='+page,
        redirect: 'follow',
    }).then(response => {
        commit('update_tourlist', response.data)
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:'API Fehler, Touren konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const getTour = ({commit,dispatch},tourId) =>{
    //count refresh tries so token refresh does not run into endless loop
    return HTTP({
        method: 'get',
        url: '/driver/tours/'+tourId,
        redirect: 'follow',
    }).then(response => {
        console.log("response getTour: ",response.data);
        commit('update_tourdetails', response.data);
    }).catch(error => {
        console.log(error);
        dispatch('setStatusMsg',{values:{text:'API Fehler, Tour konnen nicht heruntergeladen werden.', type:'error'},time:3000});
        //commit('api_error')
    });
};
const startTour = ({state,commit, dispatch},vehicleIds) => {
    return new Promise((resolve, reject) => {
        console.log("state.local.curCheck.curTourId: ",state.local.curCheck.curTourId)
        if(state.local.curCheck.curTourId == -1){
            /*var position;
            navigator.geolocation.getCurrentPosition(
            pos => {
                this.position = pos
            },
            error => {
                console.log(error.message);
            },
            )
            console.log(position.coords.latitude);
            console.log(position.coords.longitude);*/
            var payload = {
            "secondary_vehicle_ids": vehicleIds.secondaryIds,
            "geo_start": null,
            "geo_end": null,
            "date_start": null,
            "date_end": null,
            "vehicle_id": vehicleIds.truckId, // uses the company_id from the token
            }
            var raw = JSON.stringify(payload);

            console.log("raw: ",raw)
            return HTTP({
                method: 'PUT',
                headers: {"Content-Type": "application/json"},
                data: raw,
                url: '/driver/company/vehicles/'+state.local.curCheck.chosenTruck+'/tours/start',
                redirect: 'follow',
            }).then(response => {
                commit('setCurTourId',response.data);
                dispatch('setStatusMsg',{values:{text:'Neue Tour wurde erfolgreich gestartet', type:'success'},time:3000});
                resolve();
            }).catch(error => {
                console.log(error);
                commit('api_error')
                dispatch('setStatusMsg',{values:{text:'Neue Tour konnen nicht gestartet werden. Bitte überprüfe deine Internetverbindung.', type:'error'},time:3000});
                reject(error);
            });
        }else{
            dispatch('setStatusMsg',{values:{text:'Eine Fahrt läuft bereits, diese muss zuerst abgeschlossen oder abgebrochen werden, bevor eine neue Fahrt gestartet werden kann.', type:'warning'},time:8000});
            reject("WARNING: A tour is already started and stil active.")
        }
    })
};
const endTour = ({state, commit, getters, dispatch}) => {
    return HTTP({
        method: 'POST',
        url: '/driver/company/vehicles/'+state.local.curCheck.chosenTruck+'/tours/'+state.local.curCheck.curTourId+'/end',
        redirect: 'follow',
    }).then(() => {
        console.log("END TOUR BEFORE TIMER SUPPORT CHECK!!!!");
        if(getters.isFeatureSupportet('time')){
            console.log("TIMER IS SUPPORTET!!!!");
            dispatch('sendTimers').then(()=>{
                commit('finishCurCheck');
            }).catch(() => {
                commit('api_error')
                dispatch('setStatusMsg',{values:{text:'Die Tour konnte nicht abgeschlossen werden (Timner). Bitte überprüfe deine Internetverbindung.', type:'error'},time:3000});
            });
        }else{
            commit('finishCurCheck');
        }
        dispatch('setStatusMsg',{values:{text:'Neue Tour wurden erfolgreich abgeschlossen', type:'success'},time:3000});
    }).catch(error => {
        console.log(error);
        commit('api_error')
        dispatch('setStatusMsg',{values:{text:'Die Tour konnte nicht abgeschlossen werden. Bitte überprüfe deine Internetverbindung.', type:'error'},time:3000});
        //TODO: wenn tour nich abgeschlossen werden kann, lokal die endzeit zur jeweiligen tourId speichern und später zu pushen
    });
};
const setFormFieldValues = ({state, commit, dispatch}, formId) => {
    return new Promise((resolve, reject) => {
        var promises = [];
        if(state.local.checkResultsNew[formId] !== undefined){
            Object.keys(state.local.checkResultsNew[formId]).map((key) => {
                if(state.local.checkResultsNew[formId][key].type && ["boolean","radio"].includes(state.local.checkResultsNew[formId][key].type.type)
                    && state.local.checkResultsNew[formId][key].options 
                    && state.local.checkResultsNew[formId][key].options.length > 1)
                {
                    let options = state.local.checkResultsNew[formId][key].options;
                    //only use nessessary props
                    options.map((item,index) => {
                        if(item.type === "file" && 
                        Array.isArray(state.local.checkResultsNew[formId][key].options[index].fileObjects) && 
                        state.local.checkResultsNew[formId][key].options[index].fileObjects.length > 0){
                            let fileData = {
                                'formId':formId,
                                'fieldId':key,
                                'optionKey':index,
                            }
                            console.log("dispatch sendfiles: ",fileData);
                            var promi = dispatch("sendFiles",fileData);
                            promises.push(promi);
                            /*dispatch("sendFile",fileData).catch(error => {
                                fileSendError = true;
                                console.log(error);
                                commit('api_error')
                                dispatch('setStatusMsg',{values:{text:'Files konnten nicht gesendet werden. Bitte überprüfe deine Internetverbindung.', type:'error'},time:3000});
                                commit('setFormStatusSend',{formId:formId,value:'error'});
                            });*/
                            console.log("file from store: ",state.local.checkResultsNew[formId][key].options[index].fileObjects);
                        }
                    });
                }
            });
        }
        Promise.all(promises).then(() => {
            var fields = [];
            var result = {
                tour_id: state.local.curCheck.curTourId,
                fields: [],
            };
            if(state.local.checkResultsNew[formId] !== undefined){
                fields = Object.keys(state.local.checkResultsNew[formId]).map((key) => {
                    var rObj = {empty:""};
                    let value = [];
                    if(state.local.checkResultsNew[formId][key].type &&  ["boolean","radio"].includes(state.local.checkResultsNew[formId][key].type.type)
                        && state.local.checkResultsNew[formId][key].options 
                        && state.local.checkResultsNew[formId][key].options.length > 1)
                    {
                        let options = state.local.checkResultsNew[formId][key].options;
                        //only use nessessary props
                        options.map((option,index) => {
                            var serverFileIds = [];
                            if(Array.isArray(option.fileObjects) && option.fileObjects.length > 0){
                                option.fileObjects.map((item)=>{
                                    serverFileIds.push(item.serverFileId);
                                });
                            }
                            if(serverFileIds.length > 0){
                                value.push({option:index, value:option.value, selected:option.selected, files:serverFileIds});
                            }else{
                                value.push({option:index, value:option.value, selected:option.selected});
                            }
                            console.log("serverFileIds: ",serverFileIds);
                            if(option.type === "file" && 
                            Array.isArray(state.local.checkResultsNew[formId][key].options[index].fileObjects) && 
                            state.local.checkResultsNew[formId][key].options[index].fileObjects.length > 0){
                                let fileData = {
                                    'formId':formId,
                                    'fieldId':key,
                                    'optionKey':index,
                                }
                                console.log("dispatch sendfiles: ",fileData);
                                var promi = dispatch("sendFiles",fileData);
                                promises.push(promi);
                                /*dispatch("sendFile",fileData).catch(error => {
                                    fileSendError = true;
                                    console.log(error);
                                    commit('api_error')
                                    dispatch('setStatusMsg',{values:{text:'Files konnten nicht gesendet werden. Bitte überprüfe deine Internetverbindung.', type:'error'},time:3000});
                                    commit('setFormStatusSend',{formId:formId,value:'error'});
                                });*/
                                console.log("file from store: ",state.local.checkResultsNew[formId][key].options[index].fileObjects);
                            }
                        });
                        console.log("value:" ,value);
                        rObj= {'name':key, 'value':value};
                    }else{
                        rObj= {'name':key, 'value':state.local.checkResultsNew[formId][key].value};
                    }
                    return rObj;
                });
            }
            result.fields = fields
            console.log(fields);
            var raw = JSON.stringify(result);
            console.log("raw: ",raw)
            HTTP({
                method: 'POST',
                headers: {"Content-Type": "application/json"},
                data: raw,
                url: '/driver/company/vehicles/'+state.local.curCheck.chosenTruck+'/forms/'+formId+'/submit',
                //url: '/admin/forms/'+formId+'/submit',
                redirect: 'follow',
            }).then(() => {
                commit('setFormStatusSend',{formId:formId,value:'sent'});
                dispatch('setStatusMsg',{values:{text:'Formulardaten wurden erfolgreich gesendet', type:'success'},time:3000});
                if(formId === state.local.curCheck.defectsId.formId){
                    console.log("sent form is defects!!")
                    commit('saveSentDefect', state.local.checkResultsNew[formId])
                    commit('initCheckResults', state.local.checkLists[formId]);
                    //commit('resetChecklistStatus',formId);
                }
                resolve();
            }).catch(error => {
                commit('api_error')
                dispatch('setStatusMsg',{values:{text:'Formulardaten konnten nicht gesendet werden. Bitte überprüfe deine Internetverbindung.', type:'error'},time:3000});
                commit('setFormStatusSend',{formId:formId,value:'error'});
                reject(error);
            });
        }).catch((error) => {
            dispatch('setStatusMsg',{values:{text:'Dateien konnten nicht gesendet werden. Bitte überprüfe deine Internetverbindung.'+error, type:'error'},time:3000});
            reject(error);
        });
    })
};
const sendFiles = ({state, dispatch},fileData) => {
    return new Promise((resolve,reject) => {
        var fileObjects = state.local.checkResultsNew[fileData.formId][fileData.fieldId].options[fileData.optionKey].fileObjects;
        var promises = [];
        fileObjects.forEach(function(item,index){
            if(item.status === "unsent"){
                var promi = dispatch("sendFile",{'fileData':fileData,'file':item,'localFileId':index});
                promises.push(promi);
            }
        })
        Promise.all(promises).then(() => {
            resolve();
        }).catch((error)=>{
            reject(error);
        })
    });
};
 const sendFile = ({state, commit},payload) => {
    return new Promise((resolve,reject) => {
        var formdata = new FormData();
        console.log("payload.file: ",payload.file);

        
        var arr = payload.file.fileObject.dataUrl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        var file = new File([u8arr], payload.file.fileObject.info.name, {type:mime});

        console.log(file);

        formdata.append("image", file, payload.file.fileObject.name);
        //formdata.append("image", fileData.blob, fileData.blob.name);
        console.log("formdata: ",formdata);
        HTTPFILE({
            method: 'PUT',
            headers: {"Content-Type": "application/json"},
            data: formdata,
            url:"/driver/tours/"+state.local.curCheck.curTourId+"/forms/"+payload.fileData.formId+"/field/"+payload.fileData.fieldId+"/"+payload.fileData.optionKey+"/upload",
            redirect: 'follow',
        }).then((response) => {
            commit('setServerFileId',{'formId':payload.fileData.formId, 'fieldId':payload.fileData.fieldId, 'optionKey':payload.fileData.optionKey, 'serverFileId':response.data, 'localFileId':payload.localFileId});
            resolve();
        }).catch((error) => {
            reject(error);
        })
    })
 };
const sendTimers = ({commit, dispatch, state}) => {
    return new Promise((resolve, reject) => {
        var timerArray = state.local.nachtragTimer.trackedTimesEntries;
        var result = Object.keys(timerArray).map((key) => {
            var endTime;
            if(timerArray[key].endTime){
                endTime = timerArray[key].endTime;
            }else{
                endTime = Date.now();
            }
            var rObj = {
                    "user_id": state.user.user_id,
                    "company_id": state.user.company_id,
                    "tour_id": state.local.curCheck.curTourId,
                    'vehicle_id': state.local.curCheck.chosenTruck,
                    'timer_type': timerArray[key].type, 
                    'timer_start': timerArray[key].startTime,
                    'timer_end': endTime,
                    'timer_description': timerArray[key].text,
                    "mileage": timerArray[key].km,
                    "geo_start":timerArray[key].geoStart,
                    "geo_end":timerArray[key].geoEnd,
                };
            return rObj;
        });
        console.log("result: ",result);
        var raw = JSON.stringify(result);
        console.log("raw: ",raw);
        //multiple timer entries
        return HTTP({
            method: 'PUT',
            headers: {"Content-Type": "application/json"},
            data: raw,
            url: '/driver/timers/add',
            redirect: 'follow',
        }).then((response) => {
            if(response.data === false){
                commit('setFormStatusFields',{formId:'time',value:'failed'});
                commit('setFormStatusSend',{formId:'time',value:'error'});
                dispatch('setStatusMsg',{values:{text:'Zeiten konnten nicht hochgeladen werden.', type:'error'},time:3000});
                reject();
            }else{
                commit('setFormStatusSend',{formId:'time',value:'sent'});
                dispatch('setStatusMsg',{values:{text:'Zeiten erfolgreich hochgeladen.', type:'success'},time:3000});
                commit('clearTimers');
                resolve();
            }
        }).catch(error => {
            console.log(error);
            dispatch('setStatusMsg',{values:{text:'Zeiten konnten nicht hochgeladen werden. Bitte überprüfe deine Internetverbindung', type:'error'},time:3000});
            reject();
        })
    });
}; 
const reverseGeoLookup = ({commit, dispatch}, geolocation) => {
    return HTTPGEO({
        method: 'get',
        url: 'reverse-geocode-client?latitude='+geolocation.coords.latitude+'&longitude='+geolocation.coords.longitude+'&localityLanguage=de',
        redirect: 'follow',
    }).then(response => {
        commit('setAdress', response.data)
    }).catch(error => {
        console.log(error);
        //commit('api_error')
        dispatch('setStatusMsg',{values:{text:'API Error, Land konnte nicht ermittelt werden', type:'error'},time:3000});
    });
};

export default{
    login,
    logout,
    setStatusMsg,
    refreshToken,
    getProfileData,
    getChecklists,
    getVehiclelist,
    getTrailerlist,
    getTourList,
    getTour,
    startTour,
    endTour,
    setFormFieldValues,
    sendTimers,
    reverseGeoLookup,
    sendFiles,
    sendFile,
}