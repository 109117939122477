import { i18n } from '@/main.js'
//helperfunctions for Time calculations
var sumDeltaTime = (entries,type) => {
if(entries!=undefined){
    let driveEntries = entries.filter(function(obj) {
        if(obj.type !== type) {
            return false;
        }
        return true;
        });
        if(driveEntries.length > 0){
        return driveEntries.map(deltaTime).reduce(sum);
        }
        else{
        return 0;
        }
    }
}
var deltaTime = (item) => {
    let deltaTime = 0;
    if(item.endTime!=null){
        deltaTime = (item.endTime-item.startTime)/1000;
    }
    return deltaTime;
};
var sum = (prev,next) => {
    return prev+next;
};
var convertTimeString = (APITime) => {
    return ("0" + APITime.getDate()).slice(-2) + "." + ("0"+(APITime.getMonth()+1)).slice(-2) + "." + (""+APITime.getFullYear()).slice(-2) + " " + ("0" + APITime.getHours()).slice(-2) + ":" + ("0" + APITime.getMinutes()).slice(-2);
}

//getters
const noUnsentDataExists = (state) => {
    //console.log("state.local.nachtragTimer.trackedTimesEntries: ",state.local.nachtragTimer.trackedTimesEntries);
    //console.log("state.loacal.curCheck.curTourId: ",state.local.curCheck.curTourId);
    if(state.local.nachtragTimer.trackedTimesEntries.length == 0 && state.local.curCheck.curTourId == -1){
        return true;
    }
    return false;
};
/*const getLicenseDaysRemaining = (state) => {
    let nearestDateObj;
    if(state.user.license !== undefined && state.user.license !== null){
    nearestDateObj = state.user.license.reduce(function(prev, curr) {
        if(curr.valid_to !== null){
        return prev.valid_to < curr.valid_to ? prev : curr;
        }
        return prev;
    });
    }
    if(nearestDateObj != undefined){
        if(nearestDateObj.valid_to !== null && nearestDateObj.valid_to !== undefined){
            let nearestDateTimestamp = new Date(nearestDateObj.valid_to+" 00:00:00".split(' ').join('T')).getTime()
            let days = Math.round((nearestDateTimestamp - Date.now())/(1000*3600*24));
            let years = 0;
            let ret = "0";
            if(days >= 365){
                years = Math.floor(days / 365);
                if(years > 1){
                    ret = ">" + years + " " + i18n.t("general.years");
                }else{
                    ret = ">" + years + " " + i18n.t("general.year");
                }
            }else{
                if(days > 1 || days < -1){
                    ret = days + " " + i18n.t("general.days");
                }else{
                    ret = days + " " + i18n.t("general.day");
                }
            }
            return ret;
        }
    }
    return "0"
};
const getCardDaysRemaining = (state) => {
    let nearestDateObj;
    if(state.user.card !== undefined && state.user.card !== null){
        nearestDateObj = state.user.card[4];
    }
    if(nearestDateObj != undefined){
        if(nearestDateObj.value !== null && nearestDateObj.value !== undefined){
            let nearestDateTimestamp = new Date(nearestDateObj.value+" 00:00:00".split(' ').join('T')).getTime()
            let days = Math.round((nearestDateTimestamp - Date.now())/(1000*3600*24));
            let years = 0;
            let ret = "0";
            if(days >= 365){
                years = Math.floor(days / 365);
                if(years > 1){
                    ret = ">" + years + " " + i18n.t("general.years");
                }else{
                    ret = ">" + years + " " + i18n.t("general.year");
                }
            }else{
                if(days > 1){
                    ret = days + " " + i18n.t("general.days");
                }else{
                    ret = days + " " + i18n.t("general.day");
                }
            }
            return ret;
        }
    }
    return 0
};
const getIdDaysRemaining = (state) => {
    let nearestDateObj;
    nearestDateObj = state.user.idValidDate;
    //console.log("nearestDateObj: ",nearestDateObj);
    if(nearestDateObj != undefined && nearestDateObj != null){
        let nearestDateTimestamp = new Date(nearestDateObj+" 00:00:00".split(' ').join('T')).getTime()
        let days = Math.round((nearestDateTimestamp - Date.now())/(1000*3600*24));
        let years = 0;
        let ret = "0";
        if(days >= 365){
            years = Math.floor(days / 365);
            if(years > 1){
                ret = ">" + years + " " + i18n.t("general.years");
            }else{
                ret = ">" + years + " " + i18n.t("general.year");
            }
        }else{
            if(days > 1){
                ret = days + " " + i18n.t("general.days");
            }else{
                ret = days + " " + i18n.t("general.day");
            }
        }
        return ret;
    }
    return 0
};*/

const getDaysRemaining = (state) => (dateName) => {
    let nearestDateObj;
    let nearestDate;
    switch (dateName){
        case "license":
            if(state.user.license !== undefined && state.user.license !== null){
                nearestDateObj = state.user.license.reduce(function(prev, curr) {
                    if(curr.valid_to !== null){
                    return prev.valid_to < curr.valid_to ? prev : curr;
                    }
                    return prev;
                });
            }
            if(nearestDateObj == undefined){
                return 0
            }
            nearestDate = nearestDateObj.valid_to
        break;
        case "card":
            if(state.user.card !== undefined && state.user.card !== null){
                nearestDateObj = state.user.card[4];
            }
            if(nearestDateObj == undefined){
                return 0
            }
            nearestDate = nearestDateObj.value;
        break;
        case "id":
            if(state.user.idValidDate !== undefined && state.user.idValidDate !== null){    
                nearestDateObj = state.user.idValidDate;
            }
            nearestDate = nearestDateObj
        break;
        case "training":
            if(state.user.license !== undefined && state.user.license !== null){
                nearestDateObj = state.user.license.reduce(function(prev, curr) {
                    if(curr.training_date !== null){
                    return prev.training_date < curr.training_date ? prev : curr;
                    }
                    return prev;
                });
            }
            if(nearestDateObj == undefined){
                return 0
            }
            nearestDate = nearestDateObj.training_date
        break;
    }
    if(nearestDate !== null && nearestDate !== undefined){
        let nearestDateTimestamp = new Date(nearestDate+" 00:00:00".split(' ').join('T')).getTime()
        let days = Math.round((nearestDateTimestamp - Date.now())/(1000*3600*24));
        let years = 0;
        let ret = "0";
        if(days >= 365){
            years = Math.floor(days / 365);
            if(years > 1){
                ret = ">" + years + " " + i18n.t("general.years");
            }else{
                ret = ">" + years + " " + i18n.t("general.year");
            }
        }else{
            if(days > 1 || days < -1){
                ret = days + " " + i18n.t("general.days");
            }else{
                ret = days + " " + i18n.t("general.day");
            }
        }
        return ret;
    }
    return "0"
};
const getLicenses = (state) => (onlyValid) => {
    if(state.user.license != undefined && state.user.license != null){
        if(onlyValid){
            console.log("state.user.license: ",state.user.license);
            return state.user.license.filter(function(license){
                return license.valid_to !== null || license.valid_from !== null || license.training_date !== null;
            });
        }
        else{
            return state.user.license;
        }
    }
    return null;
};
const getTotalStatus = (state) => {
    var end = false;
    var defects = false;
    var check = false;
    var vehicle = false;
    var time = false;
    var status = 1;
    if(state.local.curCheck.checklistStatus != undefined){
        Object.keys(state.local.curCheck.checklistStatus).forEach((key) => {
            var elem = state.local.curCheck.checklistStatus[key];
            if(key == state.local.curCheck.lkwDataId){
                vehicle = true;
            }
            if(key == state.local.curCheck.checkListId[0].formId){
                check = true;
            }
            if(key == state.local.curCheck.defectsId.formId || state.local.curCheck.defectsId.formId < 0){
                defects = true;
            }
            if(key == state.local.curCheck.endId.formId || state.local.curCheck.endId.formId < 0){
                end = true;
            }
            if(key == state.local.curCheck.timeId){
                time = true;
            }

            //only set status if the feature is set in featureArray else ignore
            if(key !== state.local.curCheck.timeId && state.features.featureArray.includes("time")){
                if(elem.sendStatus === 'open'){
                    status = Math.max(status,2);
                }
                if(elem.fieldStatus === 'failed' && elem.sendStatus === 'sent'){
                    status = Math.max(status,3);
                }
                if(elem.sendStatus === 'error'){
                    status = Math.max(status,4);
                }
            }
        });
    }
    if(vehicle && check && defects && end && time){
        return status;
    }
    return 2
};
 
/* open, partial, failed, ok */
/* open, sent, error */
const getFormFieldStatus = (state) => (formId) =>{
    var status = 'open'
    if(state.local.curCheck.checklistStatus[formId]){
        status = state.local.curCheck.checklistStatus[formId].fieldStatus;
    }
    return status;
};
const getFormStatus = (state) => (formId) => {
    //console.log("getFormStatus formId: ",formId)
    var status = 'open'
    if(formId != undefined){
        if(state.local.curCheck.checklistStatus[formId]){
            if(state.local.curCheck.checklistStatus[formId].sendStatus === "open"){
                status = state.local.curCheck.checklistStatus[formId].fieldStatus;
            }else if(state.local.curCheck.checklistStatus[formId].sendStatus === "error"){
                status = "sendError";
            }else if(state.local.curCheck.checklistStatus[formId].sendStatus === "sent"){
                if(state.local.curCheck.checklistStatus[formId].fieldStatus === "ok"){
                    status = 'okSent';
                }else{
                    status = 'failedSent';
                }
            }
        }
    }
    return status;
};
const driveCheckStartDateTime = (state) => {
    let d = state.local.curCheck.startTime;
    //console.log("d = ",d)
    if(d != undefined){
        return convertTimeString(d);
        //return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + (""+d.getFullYear()).slice(-2) + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    }else{
        return "";
    }
    //return state.local.curCheck.startTime.split(" ")[0].substr(0,5);
};
const driveCheckStartDate = (state) => {
    let d = state.local.curCheck.startTime;
    //console.log("d: ",d)
    if(d != undefined){
        return ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." + (""+d.getFullYear()).slice(-2);
    }else{
        return "";
    }
};
const truckPlates = (state) => {
    let plates = [];
    Object.keys(state.vehiclesAPI.vehicles).forEach((key) => {
        if(state.vehiclesAPI.vehicles[key])
            plates.push({id:state.vehiclesAPI.vehicles[key].vehicle_id, value:state.vehiclesAPI.vehicles[key].license_plate});
    });
    /*state.vehicles.trucks.forEach( (vehicle) => {
    plates.push({id:vehicle.id, value:vehicle.plate});
    });*/
    return plates;
};
const trailerPlates = (state) => {
    let plates = [];
    Object.keys(state.vehiclesAPI.trailers).forEach((key) => {
        if(state.vehiclesAPI.trailers[key])
            plates.push({id:state.vehiclesAPI.trailers[key].vehicle_id, value:state.vehiclesAPI.trailers[key].license_plate});
    });
    return plates;
};
const currVehiclePlate = (state) => {
    if(state.local.curCheck.chosenTruck === -1){
        return "/";
    }
    return state.vehiclesAPI.vehicles[state.local.curCheck.chosenTruck].license_plate;
};
const currTrailerPlate = (state) => {
    if(state.local.curCheck.chosenTrailer === -1){
        return "/";
    }
    return state.vehiclesAPI.trailers[state.local.curCheck.chosenTrailer].license_plate;
}
const getFieldgroupIdsByCheckListId = (state) => (checkListId) => {
    return state.local.checkLists[checkListId].fieldGroups;
};

const getFieldgroupByGroupId = (state) => (groupId) => {
    return state.local.fieldGroups[groupId];
};
const getFieldsByGroupId = (state) => (groupId) => {
    let fields = [];
    state.local.fieldGroups[groupId].fields.forEach(function(item){
    fields = [...fields,item];
    //console.log("fields: ",fields);
    });
    
    return fields;
};
const getFieldById = (state) => (fieldId) => {
    if(fieldId in state.local.fields){
    return state.local.fields[fieldId];
    }
    return null;
};
//getter with arguments only possible when returning a function that takes the arguments
const getFieldResult = (state) => (ids) => {
    if(ids.formId in state.local.checkResultsNew){
        if(ids.fieldId in state.local.checkResultsNew[ids.formId]){
            let field = state.local.checkResultsNew[ids.formId][ids.fieldId];
            if(["boolean"].includes(field.type.type)){
                if(field.options && field.options.length > 1){
                    if(field.trueOption === field.value){
                        return true;
                    }else if(field.falseOption === field.value){
                        return false;
                    }
                }
            }
            return field.value;
        }
    }
    return '';
};
//should be called getFieldState!?
const getGroupState = (state) => (ids) => {
    if(ids.formId in state.local.checkResultsNew){
        if(ids.fieldId in state.local.checkResultsNew[ids.formId]){
            return state.local.checkResultsNew[ids.formId][ids.fieldId].complete;
        }
    }
    return 'initial';
};

//check if any fields of the group are locked
const getLockedFieldsFromGroup = (state) => (ids) => {
    let locked = [];
    if(ids.formId in state.local.checkResultsNew){
        //iterate all children of the group and add locked fieldIds to locked array
        Object.keys(state.local.checkResultsNew[ids.formId]).forEach(function(key){
            let field = state.local.checkResultsNew[ids.formId][key];
            if(field.parentId === ids.fieldId){
                //console.log("field: ",field.fieldId, "isLocked: ",field.lockedBy);
                if(!(field.lockedBy === false || field.lockedBy === 'unlocked') && field.skip === false){
                    locked.push(field.fieldId);
                }
            }
        });
    }
    return locked;
};

//TODO should use the accessToken state.tokens.accessToken to check if isLoggedIn
//isLoggedIn: state => !!state.tokens.accessToken,
const authStatus = (state) => {
    return state.status.value || ''
};
const getDocuEntries = state => (page) => {
    return state.local.documentation.finishedChecks[page];
};
const getTime = state => (type) => {
    return sumDeltaTime(state.local.nachtragTimer.trackedTimesEntries, type)
};
/*const getWorkTime = state => {
    return sumDeltaTime(state.local.nachtragTimer.trackedTimesEntries, 'work')
};
const getPauseTime = state => {
    return sumDeltaTime(state.local.nachtragTimer.trackedTimesEntries, 'pause')
};
const getReadyTime = state => {
    return sumDeltaTime(state.local.nachtragTimer.trackedTimesEntries, 'ready')
};*/

const isFeatureSupportet = (state) => (feature) => {
    if(Array.isArray(state.features.featureArray) && state.features.featureArray.includes(feature)){
        return true;
    }
    return false;
};

const getValueForCurLanguage = () => (values) => {
    //state.languageCode deprecated -> unused -> remove
    if(Array.isArray(values)){
        let val = values.filter(value => value.language === i18n.locale);
        return val[0];
    }
    return values;
};

const getNextRoute = (state) => (actualRoute) => {
    //TODO: mehrere checklisten etc. vorsehen, globale form reihenfolge definieren oder so
    console.log(actualRoute);
    switch(actualRoute){
        case "checklist":
            if(state.local.curCheck.defectsId.baseId > -1){
                return "/check/defects";
            }else if(state.local.curCheck.toDoId.baseId > -1){
                return "/check/todo";
            }else if(state.features.featureArray.includes("time")){
                return "/check/time";
            }else{
                return "/check";
            }
        case "defects":
            if(state.local.curCheck.toDoId.baseId > -1){
                return "/check/todo";
            }else if(state.features.featureArray.includes("time")){
                return "/check/time";
            }else{
                return "/check";
            }
        case "todo":
            if(state.features.featureArray.includes("time")){
                return "/check/time";
            }else{
                return "/check";
            }
        case "time":
            if(state.local.curCheck.endId.baseId > -1){
                return "/check";
            }
    }

};

export default{
    noUnsentDataExists,
    getDaysRemaining,
    //getLicenseDaysRemaining,
    //getCardDaysRemaining,
    //getIdDaysRemaining,
    getTotalStatus,
    getFormFieldStatus,
    getFormStatus,
    driveCheckStartDateTime,
    driveCheckStartDate,
    truckPlates,
    trailerPlates,
    currVehiclePlate,
    currTrailerPlate,
    getFieldgroupIdsByCheckListId,
    getFieldgroupByGroupId,
    getFieldsByGroupId,
    getFieldById,
    getFieldResult,
    getGroupState,
    getLockedFieldsFromGroup,
    authStatus,
    getDocuEntries,
    getTime,
    isFeatureSupportet,
    getValueForCurLanguage,
    getNextRoute,
    getLicenses
    /*getDriveTime,
    getWorkTime,
    getPauseTime,
    getReadyTime,*/
}