import { setState } from '../storage';

const shouldSkipCache = (mutation) => {
  //SoNo: delete this output its only there, that var is not unused
  //console.log("mutation: ",mutation)
  if(mutation.type in ['set_satus_msg_active','activateConfirmPopup','deactivateConfirmPopup']){
    return true;
  }
  //default case
  return false;
};

const plugin = (store) => {
  store.subscribe((mutation, state) => {
    //console.log("mutation: ", mutation, "state: ",state.local.curCheck.checkListId.formId)
    if (!shouldSkipCache(mutation)) {
      setState(state).then(function(response){
          //console.log("tmpState: ", response);
          //console.log("formId before if: ",response.local.curCheck.checkListId.formId);
          if(response.local.curCheck.checkListId[0].formId == -1){
            //console.log("formId in if: ",response.local.curCheck.checkListId.formId);
          }
      }).catch(err => console.warn('failed to cache state', err));
    }
  });
};

export default plugin;